<template>
  <div id="center">
    <HomePageTop />
    <div class="userCenter">
      <div class="userindexBody">
        <!--左侧菜单及用户信息展示-->
        <div class="leftUserMessageContainer">
          <!--用户信息展示 -->
          <div class="userMessageHeaderContainer">
          </div>
          <!-- 菜单栏 -->
          <div class="menuBar">
            <!-- 首页 -->
            <div class="home_page" @click="toHomePage">
              <img src="../../assets/user-img/new-menu/home_1.svg" alt="">
              <span>
                {{ $fanyi('首页') }}
              </span>
            </div>
            <!-- 导航列表 -->
            <div class="nav_list">
              <ul>
                <li v-for="(item, index) in activeMenu" :key="item.id">
                  <div class="menu_title">
                    <img :src="item.activeStatus === true ? item.activeImgUrl : item.imgUrl
                      " alt="">
                    <span>
                      {{ item.name }}
                    </span>
                  </div>
                  <div class="menu_children_item" v-for="(child, ind) in item.arr" :key="child.id"
                    @click="menuChildActive(child, index, ind)">
                    <div class="menu_children" :class="{ actived_menu_child: child.activeStatus == true }">
                      <span :class="{ titleflex: child.url == 'favoriteproduct' }" class="title">
                        {{ child.name }}
                      </span>
                      <span class="count" v-if="index == 0 || index == 1">
                        ({{ child.count ? child.count : 0 }})
                      </span>

                    </div>
                  </div>


                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="contentBar">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="1200px" :lock-scroll="false" :before-close="handleClose">
      <div class="InformBounced ql-editor">
        <h1>{{ readItem.title }}</h1>
        <p class="timeBox">{{ readItem.created_at }}</p>
        <div class="con" v-html="readItem.content"></div>
      </div>
      <button class="xioxibtn" @click="handleClose">{{ $fanyi('关闭') }}</button>
    </el-dialog>

    <Foot />
  </div>
</template>
<script>
import HomePageTop from '../../components/head/HomePageTop.vue'
import Foot from '../../components/foot/Foot.vue'
import router from '@/router'

export default {
  data() {
    return {
      tableData: [],
      readItem: {},
      isRouterAlive: true,

      UserInfo: {},
      count: 0,
      dialogVisible: false,  //公告·消息提示
      shopLoading: true,
      levelImg: '',
      activeMenu: [
        {
          id: '2',
          name: this.$fanyi('订单管理'),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require('../../assets/user-img/new-menu/list_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/list_1.svg'),
          arr: [
            {
              id: '3',
              name: this.$fanyi('订单待处理'),
              url: 'userOrder',
              activeStatus: false,
              count: this.$store.state.leftSidebarNum[0].num,
            },
            {
              id: '4',
              name: this.$fanyi('问题产品'),
              url: 'faultyproducts',
              count: this.$store.state.leftSidebarNum[1].num,
              activeStatus: false,
            },


            {
              id: '22',
              name: this.$fanyi('仓库'),
              url: 'Warehouse',
              activeStatus: false,
              count: this.$store.state.leftSidebarNum[5].num,
            },
            {
              id: '6',
              name: this.$fanyi('订单运输中'),
              url: 'Packinglist',
              count: this.$store.state.leftSidebarNum[3].num,
              activeStatus: false,
            },

          ],
        },

        {
          id: '11',
          name: this.$fanyi('收藏夹'),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require('../../assets/user-img/new-menu/collection_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/collection_1.svg'),
          arr: [
            {
              id: '12',
              name: this.$fanyi('收藏商品'),
              url: 'favoriteproduct',
              activeStatus: false,
              count: this.$store.state.leftSidebarNum[6]?.num,
            },
            {
              id: '13',
              name: this.$fanyi('店铺收藏'),
              url: 'favoriteshop',
              activeStatus: false, count: this.$store.state.leftSidebarNum[7]?.num,
            },
            {
              id: '14',
              name: this.$fanyi('商品库'),
              url: 'productlist',
              activeStatus: false,
              count: this.$store.state.leftSidebarNum[8]?.num,
            },
          ],
        },
        {
          id: '15',
          name: this.$fanyi('个人资料'),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require('../../assets/user-img/new-menu/information_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/information_1.svg'),
          arr: [
            {
              id: '16',
              name: this.$fanyi('修改个人信息'),
              url: 'userDetail',
              activeStatus: false,
            },
            {
              id: '17',
              name: this.$fanyi('地址管理'),
              url: 'deliverySite',
              activeStatus: false,
            },
            {
              id: '18',
              name: this.$fanyi('密码修改与验证'),
              url: 'changepassword',
              query: {
                type: 'password',
              },
              activeStatus: false,
            },
          ],
        },
        {
          id: '19',
          name: this.$fanyi('资金详情'),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require('../../assets/user-img/new-menu/points_0.svg'),
          activeImgUrl: require('../../assets/user-img/new-menu/points_1.svg'),
          arr: [
            {
              id: '20',
              name: this.$fanyi('出入金记录'),
              url: 'paymentsummary',
              activeStatus: false,
            },
          ],
        },

      ],


      isActivedHome: false
    }
  },
  components: {
    Foot,
    HomePageTop,

  },
  computed: {
    activePage() {
      return this.$route.name
    },

    userInfo() {
      return this.$store.state.userInfo
    },

  },
  created() {
    // 获取侧边栏数据
    this.$store.dispatch('getLeftSidebarNum')
    // 根据路由判断状态
    this.listenSignStatus()
    // 获取消息提示看看有没有消息
    this.getNoticeUnreadNoticeCount()

    // 异步等待侧边栏数据获取成功后赋值
    this.$nextTick(() => {
      setTimeout(() => {
        this.activeMenu = [
          {
            id: '2',
            name: this.$fanyi('订单管理'),
            activeStatus: false,
            downIconStatus: true,
            imgUrl: require('../../assets/user-img/new-menu/list_0.svg'),
            activeImgUrl: require('../../assets/user-img/new-menu/list_1.svg'),
            arr: [
              {
                id: '3',
                name: this.$fanyi('订单待处理'),
                url: 'userOrder',
                activeStatus: false,
                count: this.$store.state.leftSidebarNum[0].num,
              },
              {
                id: '4',
                name: this.$fanyi('问题产品'),
                url: 'faultyproducts',
                count: this.$store.state.leftSidebarNum[1].num,
                activeStatus: false,
              },


              {
                id: '22',
                name: this.$fanyi('仓库'),
                url: 'Warehouse',
                activeStatus: false,
                count: this.$store.state.leftSidebarNum[5].num,
              },
              {
                id: '6',
                name: this.$fanyi('订单运输中'),
                url: 'Packinglist',
                count: this.$store.state.leftSidebarNum[3].num,
                activeStatus: false,
              },

            ],
          },

          {
            id: '11',
            name: this.$fanyi('收藏夹'),
            activeStatus: false,
            downIconStatus: true,
            imgUrl: require('../../assets/user-img/new-menu/collection_0.svg'),
            activeImgUrl: require('../../assets/user-img/new-menu/collection_1.svg'),
            arr: [
              {
                id: '12',
                name: this.$fanyi('收藏商品'),
                url: 'favoriteproduct',
                activeStatus: false,
                count: this.$store.state.leftSidebarNum[6]?.num,
              },
              {
                id: '13',
                name: this.$fanyi('店铺收藏'),
                url: 'favoriteshop',
                activeStatus: false, count: this.$store.state.leftSidebarNum[7]?.num,
              },
              {
                id: '14',
                name: this.$fanyi('商品库'),
                url: 'productlist',
                activeStatus: false,
                count: this.$store.state.leftSidebarNum[8]?.num,
              },
            ],
          },
          {
            id: '19',
            name: this.$fanyi('资金详情'),
            activeStatus: false,
            downIconStatus: true,
            imgUrl: require('../../assets/user-img/new-menu/points_0.svg'),
            activeImgUrl: require('../../assets/user-img/new-menu/points_1.svg'),
            arr: [
              {
                id: '20',
                name: this.$fanyi('出入金记录'),
                url: 'paymentsummary',
                activeStatus: false,
              },
              {
                id: '21',
                name: this.$fanyi('优惠券'),
                url: 'coupon',
                activeStatus: false,
              },
            ],
          },
          {
            id: '15',
            name: this.$fanyi('个人资料'),
            activeStatus: false,
            downIconStatus: true,
            imgUrl: require('../../assets/user-img/new-menu/information_0.svg'),
            activeImgUrl: require('../../assets/user-img/new-menu/information_1.svg'),
            arr: [
              {
                id: '16',
                name: this.$fanyi('修改个人信息'),
                url: 'userDetail',
                activeStatus: false,
              },
              {
                id: '17',
                name: this.$fanyi('地址管理'),
                url: 'deliverySite',
                activeStatus: false,
              },
              {
                id: '18',
                name: this.$fanyi('密码修改与验证'),
                url: 'changepassword',
                query: {
                  type: 'password',
                },
                activeStatus: false,
              },
            ],
          },


        ]
      }, 1800)
    })
  },
  mounted() { },
  methods: {
    //去首页，重置所有状态
    toHomePage() {
      this.$router.push({ name: 'userIndex' })
      this.activeMenu.forEach(menu => {
        menu.activeStatus = false
        menu.arr.forEach(child => {
          child.activeStatus = false
        })
      })
    },
    // 根据路由判断状态
    listenSignStatus() {
      for (let i = 0; i < this.activeMenu.length; i++) {
        if (this.activeMenu[i].arr != undefined) {
          for (let j = 0; j < this.activeMenu[i].arr.length; j++) {
            if (this.activeMenu[i].arr[j].url === this.$route.name) {
              this.activeMenu[i].activeStatus = true
              this.activeMenu[i].downIconStatus = true
              this.activeMenu[i].arr.forEach((item, index) => {
                item.activeStatus = j === index
              })
              break
            } else {
              this.activeMenu[i].activeStatus = false
              this.activeMenu[i].downIconStatus = false
              this.activeMenu[i].arr[j].activeStatus = false
            }
          }
        } else {
          this.activeMenu[0].activeStatus = false
        }
      }
    },
    // 获取看是否有消息提示
    getNoticeUnreadNoticeCount() {
      //favoriteGoodsList
      this.$api
        .noticeUnreadNotice()
        .then((res) => {
          if (res.data.length > 0) {
            this.dialogVisible = true

            this.readItem = res.data[0]

          }
          this.count = res.data.length

        })
        .catch((err) => { })
    },
    // 弹窗关闭
    handleClose() {
      this.$api.noticeReadNotice({ id: this.readItem.id }).then((res) => {
        this.dialogVisible = false
        this.getNoticeUnreadNoticeCount()
      });


    },

    befoUpload(file) {
      const isJPG = file.type.indexOf('image') !== -1
      if (!isJPG) {
        this.$message.error(this.$fanyi('请上传图片'))
        return isJPG
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi('图片上传中'),
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, .7)',
      })
    },
    // 图片上传成功事件
    handleAvatarSuccess(res) {
      this.$api.userPortraitSave({ head_img: res.data }).then((res) => {
        if (res.code != 0) return
        this.$api.EuropegetUserInfo().then((res) => {
          this.$store.commit('userData', res.data)
          this.loading.close()
          this.$forceUpdate()
        })
      })
    },

    //点击子菜单
    menuChildActive(item, index, childInd) {

      //index是父级的索引
      item.activeStatus = true
      this.activeMenu.forEach((menu, ind) => {
        if (ind === index) {

          menu.arr.forEach((child, childIndex) => {
            if (childIndex != childInd) {
              child.activeStatus = false
            }
          })
        } else {

          menu.arr.forEach((child, childIndex) => {
            child.activeStatus = false
          })
        }
      })
      this.$router.push({ name: item.url, query: item.query })
    },
    //更新子菜单的选中项
    updateSelectChildrenMenu(index, index1) {

      this.activeMenu.forEach((item, menuIndex) => {
        if (menuIndex === index) {
          this.activeMenu[menuIndex].activeStatus = true
          this.activeMenu[menuIndex].downIconStatus = true
          item.arr.forEach((items, menuSelectIndex) => {
            items.activeStatus = index1 === menuSelectIndex
          })
        } else {
          this.activeMenu[menuIndex].activeStatus = false
          if (item.arr != undefined) {
            item.arr.forEach((items) => {
              items.activeStatus = false
            })
          }
        }
      })
    },

  },
}
</script>
<style lang="scss" scoped>
@import '../../css/mixin';

#center {
  background: #f6f6f6;
}

// 消息提示弹框
.InformBounced {
  background: #fafafa;
  margin: 0 auto;
  border-radius: 10px;
  width: 1120px;
  height: 500px;
  padding: 20px 40px 40px;
  background-color: #fafafa;
  overflow: auto;

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

    margin-bottom: 10px;
    text-align: center;
  }

  .timeBox {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
  }

  .con {
    height: 20px;
    font-size: 14px;
  }
}

/deep/.el-dialog {
  border-radius: 6px;

  .el-dialog__header {
    padding: 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ededed;
    height: 60px;
  }
}

.xioxibtn {
  margin: auto;
  display: flex;
  width: 162px;
  height: 48px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #FF730B;
  margin-top: 10px;
  margin-bottom: 20px;

}

/deep/.el-dialog__body {
  padding: 0 30px !important;
}



.userCenter {
  width: 1400px;
  margin: 0 auto 30px;

  //头部
  .userindexhead {
    width: 100%;
    height: 140px;
    margin: 20px 0;
  }

  .u-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  //内容
  .userindexBody {
    display: flex;
    align-items: flex-start;
    width: 240px;

    .leftUserMessageContainer {
      .userMessageHeaderContainer {
        .userMessageContainerTop {
          display: flex;
          align-items: center;
          padding: 20px 27px;
          $userAvatar: 52px;

          .upload-demo {
            position: relative;

            &:hover .showDow {
              opacity: 1;
            }

            .showDow {
              background-color: rgba($color: #000000, $alpha: 0.5);
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 0;
              transition: 0.3s;
              top: 0;
              left: -40px;
              border-radius: 50%;
              width: $userAvatar;
              margin: 0 30px 0 40px;
              height: $userAvatar;
              color: white;
              font-size: 12px;
            }
          }

          img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            margin-right: 16px;
          }

          .userName {
            .top {
              font-size: 18px;
              padding: 0;
              display: flex;
              align-items: center;

              div:last-child {
                font-weight: bold;
              }
            }

            .bottom {
              font-size: 14px;
            }
          }
        }



        .userMessageContainerBottom {
          width: 320px;
          height: 56px;
          background: #fff;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
          border: 1px solid #e2e2e2;
          margin-bottom: 29px;
          padding: 13px 10px 13px 19px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .userMessageBottomContainerLeft {
            display: flex;
            align-items: center;

            div {
              margin-left: 11px;
            }

            img {
              width: 18px;
              height: 18px;
            }
          }

          .userMessageBottomContainerRight {
            width: 36px;
            height: 36px;
            background: #ff730b;

            border-radius: 6px;
            color: #fff;
            font-size: 16px;
            line-height: 36px;
            text-align: center;
          }
        }
      }

      //侧边栏
      .menuBar {
        width: 200px;
        min-height: 300px;
        margin-right: 20px;
        // 菜单内容左间距
        $fontMarginLeft: 30px;

        .home_page {
          width: 200px;
          height: 48px;
          padding-left: 20px;
          background: #FF730B;
          color: #FFFFFF;
          border-radius: 6px;
          font-size: 14px;

          line-height: 18px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }



        .nav_list {
          width: 200px;
          border-radius: 6px;
          margin-top: 10px;

          ul {
            width: 100%;

            li {
              .menu_title {
                height: 48px;
                background: #F0F0F0;
                display: flex;
                align-items: center;
                padding-left: 20px;
                font-weight: normal;
                font-size: 14px;
                color: #222222;

                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                }
              }

              .menu_children_item {

                font-weight: normal;
                font-size: 14px;
                color: #222222;
                display: flex;
                align-items: center;
                min-height: 40px;

                border-bottom: 1px solid#F6F6F6;
                line-height: 18px;
                background-color: #fff;


                &:hover {
                  cursor: pointer;
                  background: #FF730B;
                  font-size: 14px;
                  color: #FFFFFF;

                }

                .menu_children {
                  width: 100%;
                  min-height: 40px;
                  padding: 8px 0px;

                  padding-left: 30px;
                  padding-right: 20px;
                  display: flex;
                  align-items: center;
                  text-align: left;
                  // white-space: nowrap;

                  // .title {}
                  .titleflex {
                    // width: 58px;
                  }

                  .count {
                    margin-left: 10px;

                  }

                }
              }

              .actived_menu_child {
                font-size: 14px;

                background: #FF730B;
                color: #FFFFFF;
              }
            }

            li:first-child {
              .menu_title {
                border-radius: 6px 6px 0 0;
              }

            }

            li:last-child {
              .menu_children_item:last-child {
                border-radius: 0 0 6px 6px;
              }
            }


          }
        }


      }
    }


  }
}
</style>
